import React from 'react'
import { Link } from 'gatsby'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar has-text-black is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
        style={{ backgroundColor: 'transparent' }}
      >
        <div className={`container navbar-container ${this.state.navBarActiveClass}`}>
          <div className="navbar-brand">
            {/* Hamburger menu  */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass} ${ this.props.page_name === 'home' ? 'home' : '' }`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end ml-0 has-text-centered" style={{ }}>
              <Link className={`navbar-item ${ this.props.page_name === 'home' ? 'active' : '' }`} to="/">
                Hjem
              </Link>
              <Link className={`navbar-item ${ this.props.page_name === 'stiftelsen' ? 'active' : '' }`} to="/stiftelsen">
                Stiftelsen
              </Link>
              <Link className={`navbar-item ${ this.props.page_name === 'styret' ? 'active' : '' }`} to="/styret">
                Styret
              </Link>
              <Link className={`navbar-item ${ this.props.page_name === 'formal' ? 'active' : '' }`} to="/formal">
                Formål
              </Link>
              <Link className={`navbar-item ${ this.props.page_name === 'fn' ? 'active' : '' }`} to="/fn">
                FNs bærekraftsmål
              </Link>
              <Link className={`navbar-item ${ this.props.page_name === 'vedtekter' ? 'active' : '' }`} to="/vedtekter">
                Vedtekter
              </Link>    
              <Link className={`navbar-item ${ this.props.page_name === 'samarbeidspartnere' ? 'active' : '' }`} to="/samarbeidspartnere">
                Samarbeidspartnere
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
