import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import showdown from 'showdown'

export const HeroPageTemplate = ({ title, page_name, description, image, content }) => {
  const converter = new showdown.Converter();
  const backgroundImage = typeof image === 'string' ? image : image.childImageSharp.fluid.src

  return (
  <main id="index" className="hero frontpage">

    <Navbar page_name={page_name}/>

    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container is-centered">
          
          <img src="/img/logony.png"/>
        </div>
      </div>
    </section>
  </main>
  )
}

HeroPageTemplate.propTypes = {
  image: PropTypes.object.isRequired,
  page_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

const HeroPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <HeroPageTemplate
        page_name={post.frontmatter.page_name}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        content={post.frontmatter.content}
      />
    </Layout>
  )
}

HeroPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HeroPage

export const HeroPageQuery = graphql`
  query HeroPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        page_name
        title
        description
        content
      }
    }
  }
`
